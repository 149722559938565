<template>
    <div class="rate-info__item">
        <button type="button" class="btn-block-option close-modal" @click="$modal.hide('History')"> ×</button>
        <div class='rate-info__label'>Последние оплаты:</div>
        <loading-spinner v-if="!isModalLoaded"/>
        <div v-else class="rate-info__value d-flex flex-column align-items-start">
            <ul v-if="history.length > 0" class="rate-info__payments">
                <li v-for="item in history" :item="item" :key="item.id" class="rate-info__payments-item">{{item.date |
                    moment('D MMMM YYYY HH:mm') }} - {{item.amount}} р
                </li>
            </ul>
            <span class="rate-info__payments-item" v-else>Нет оплат</span>
        </div>
    </div>
</template>

<script>
    import session from '@/api/session';

    export default {
        name: "History",
        components: {
            LoadingSpinner : () => import('@/components/LoadingSpinner')
        },
        data() {
            return {
                history: [],
                isModalLoaded: false
            }
        },
        mounted() {
            session
                .get('/api/v1/settings/history/')
                .then(response => {
                    this.history = response.data;
                    this.isModalLoaded = true;
                })
                .catch(() => {
                });
        },
    }
</script>

<style lang="scss">

    .rate-info {
        &__payments {
            height: 100%;
            overflow-y: auto;
            width: 100%;
            padding-bottom: 30px;
        }

    }

    .v--modal-overlay[data-modal="History"] {
        .v--modal-box {
            padding: 30px;
            height: 500px;
            max-width: 500px !important;
            margin: 0 auto;
            overflow: visible;
        }
        .rate-info__value,
        .rate-info__item {
            height: 100% !important;
        }
        .rate-info__value {
            padding-bottom: 30px;
        }
    }

</style>
